/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "All programs have to manage memory."), "\n", React.createElement(_components.p, null, "The stack and the heap are 2 places in memory where Rust stores information."), "\n", React.createElement(_components.p, null, "In most programming languages (eg. JavaScript, Python, Java, etc.), you don’t have to worry about this."), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Stack_(abstract_data_type)"
  }, "stack"), " is a part of memory that has a specific data structure."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When there is a new piece of data that should be stored, the stack will always put it “on top”. (", React.createElement(_components.em, null, "push"), " to the stack)"), "\n", React.createElement(_components.li, null, "When a piece of data should be removed from the stack, it is also taken “from the top”. (", React.createElement(_components.em, null, "pop"), " off the stack)"), "\n"), "\n", React.createElement(_components.p, null, "More succinctly, it’s a ", React.createElement(_components.em, null, "last in, first out (LIFO)"), " data structure."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "All entries that are pushed to the stack are required to have a known, fixed size.")), "\n", React.createElement(_components.p, null, "The size should be known at compile time, and can not change during runtime.\nWe can not put things of variable size in the stack (like a string that might grow in size)."), "\n", React.createElement(_components.p, null, "If we want to store such data, we have to use the ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Memory_management#HEAP"
  }, "heap"), ".\nAs the name suggests, the heap is not as organized as the stack."), "\n", React.createElement(_components.p, null, "When we want to store some data on the heap, we ask the memory allocator (a piece of logic that manages the memory of the heap) for a given amount of memory.\nThe memory allocator finds a free spot in the heap, stores it there, marks it as occupied, and returns a pointer to that location in memory.\nThat process is called allocating on the heap."), "\n", React.createElement(_components.p, null, "That pointer has a known, fixed size.\nAlong with a tiny amount of other information, it is stored on the stack.\nWhen the program wants to access the data, it has to follow that pointer to the location of that data in the heap."), "\n", React.createElement(_components.p, null, "The heap is slower than the stack, but it is more flexible."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
